


import { Component, Vue, Watch } from 'vue-property-decorator';
import { Action, Getter, Mutation } from 'vuex-class';
import { TPromoPage } from '@/_types/promo-page/promo-page.type';
import { TBroadcast } from '@/_types/broadcasts/broadcast.type';
import { TPromoPageFavoriteParams } from '@/_modules/promo/api/promo-page.api';
import { TPromoPageStoreState } from '@/_modules/promo/types/promo-page-store-state.type';
import IconStar from '@/_modules/icons/components/icon-star.vue';
import IconMessage from '@/_modules/icons/components/icon-message.vue';
import broadcastsService from '@/_services/broadcasts.service';
import ChatHelper from '@/_modules/chat/helpers/chat.helper';
// @ts-ignore
import truncate from 'vue-truncate-collapsed';

const NO_AVATAR_COMPANY = require('@/assets/images/no-avatar-company-60x60.svg');
const LIVE_INDICATOR = require('@/assets/images/live-indicator.svg');

@Component({
  components: {
    truncate,
    IconStar,
    IconMessage,
  },
})
export default class CompaniesMap extends Vue {

  @Getter('seatPlanStore/isLoading') planIsLoading: boolean;
  @Getter('seatPlanStore/planJson') planJson: any;
  @Getter('seatPlanStore/eventId') planEventId: number;
  @Getter('seatPlanStore/isPlanJson') isPlanJson: boolean;
  @Getter('promoStore/promoPageListLoading') promoPageListLoading: boolean;
  @Getter('promoStore/promoPageList') promoPageList: TPromoPage[];

  @Action('promoPageStore/setListAll') setListAll: () => Promise<TPromoPage[]>;
  @Action('promoPageStore/addFavorite') addFavorite: (payload: TPromoPageFavoriteParams) => Promise<void>;
  @Action('promoPageStore/removeFavorite') removeFavorite: (payload: TPromoPageFavoriteParams) => Promise<void>;

  @Mutation('promoPageStore/SET_VIEW_TYPE') SET_VIEW_TYPE: (type: TPromoPageStoreState['viewType']) => void;
  @Mutation('promoPageStore/SET_LIST_TYPE') SET_LIST_TYPE: (type: TPromoPageStoreState['listType'] ) => void;

  public currentPromoPage: TPromoPage = null;
  public clientX: number = null;
  public clientY: number = null;
  public popupShowLeft: boolean = false;
  public popupShowTop: boolean = false;
  public promoPageMapByExternalId: Map<string, TPromoPage> = new Map();
  public seatPlanViewer: any = null; // TODO: type for this HTML Element that has custom events
  public broadcastItems: string[] = [];
  public activeBroadcasts: TBroadcast[] = [];
  public findBroadCastInterval: number = null;
  public promoPagesInterval: number = null;

  public get eventId(): number {
    return (this.$route.params.eventId && parseInt(this.$route.params.eventId, 10)) || null;
  }

  public get isSeatPlanVisible(): boolean {
    return (
      this.planEventId === this.eventId
      && !this.planIsLoading
      && !this.promoPageListLoading
      && !!this.planJson
    );
  }

  public get currentPromoPageAvatarSrc(): string {
    return (this.currentPromoPage && this.currentPromoPage.logo_url) || NO_AVATAR_COMPANY;
  }

  public get seatPlanIframeSrc(): string {
    return `/${this.$route.params.lang}/seat-plan`;
  }

  @Watch('promoPageList', { immediate: true })
  private onPromoPageListChange(): void {
    this.promoPageMapByExternalId.clear();
    if (!this.promoPageList || !this.promoPageList.length) {
      return;
    }
    this.promoPageList.forEach(promoPage => {
      if (promoPage.external_id) {
        this.promoPageMapByExternalId.set(promoPage.external_id, promoPage);
      }
    });
  }

  @Watch('activeBroadcasts')
  private onActiveBroadcastsChange(): void {
    if (this.activeBroadcasts.length === this.broadcastItems.length) {
      return;
    }

    const frameBody = (document.querySelector('.seat-plan-iframe') as HTMLIFrameElement).contentWindow.document.body;
    (window as any).companies = frameBody.querySelectorAll('.label');

    frameBody.querySelectorAll('.icon-live-indicator').forEach(item => {
      item.remove();
    });
  }

  public created(): void {
    document.addEventListener('click', this._onDocumentClick);
  }

  public mounted(): void {
    this._getSeatPlanJson();
  }

  public beforeDestroy(): void {
    clearInterval(this.findBroadCastInterval);
    clearInterval(this.promoPagesInterval);

    const frame = document.querySelector('.seat-plan-iframe') as HTMLIFrameElement;
    if (frame) {
      (window as any).companies = frame.contentWindow.document.body.querySelectorAll('.label');

      const indicators = frame.contentWindow.document.body.querySelectorAll('.icon-live-indicator');

      indicators.forEach(item => {
        item.remove();
      });
    }
  }

  public destroyed(): void {
    clearInterval(this.findBroadCastInterval);
    clearInterval(this.promoPagesInterval);
    document.removeEventListener('click', this._onDocumentClick);
    if (this.$refs.setPlanIframe) {
      (this.$refs.setPlanIframe as HTMLIFrameElement).contentWindow.document.removeEventListener('click', this._onDocumentClick);
    }
    if (this.seatPlanViewer) {
      this.seatPlanViewer.removeEventListener('layerchange', this.onViewerLayerChange);
      this.seatPlanViewer.removeEventListener('shapeclick', this.onViewerShapeClick);
    }
  }

  public onShowCompanyClick(external_id: string): void {
    this.SET_VIEW_TYPE('list');
    this.SET_LIST_TYPE('all');
    this.setListAll();
    this.$router.push({
      name: 'promo-page-events-company',
      params: {
        eventId: this.$route.params.eventId,
        external_id,
      }
    }).catch(() => {
      /* ignore */
    });
  }

  public toggleFavorite(): void {
    const payload = {
      event_id: this.$route.params.eventId,
      external_id: this.currentPromoPage.external_id
    };
    this.currentPromoPage.is_favorite ? this.removeFavorite(payload) : this.addFavorite(payload);
    this.currentPromoPage.is_favorite = !this.currentPromoPage.is_favorite;
  }

  public onViewerShapeClick(event: CustomEvent): void { // TODO: add type for CustomEvent<T>
    this.currentPromoPage = null;
    const { detail: { shape, originalEvent } } = event;

    if (shape.TYPE !== 'booth' || !shape.label) {
      return;
    }

    const promoPage = this.promoPageMapByExternalId.get(shape.label);
    if (!promoPage) {
      return;
    }
    this.popupShowLeft = false;
    this.popupShowTop = false;

    let x = originalEvent.clientX;
    let y = originalEvent.clientY;

    if (this.$refs.setPlanIframe) {
      const boundingRect = (this.$refs.setPlanIframe as HTMLIFrameElement).getBoundingClientRect();
      if (boundingRect) {
        x += boundingRect.x;
        y += boundingRect.y;
      }
    }

    this.clientX = x;
    this.clientY = y;
    this.currentPromoPage = promoPage;
    originalEvent.stopPropagation();

    this.$nextTick(() => {
      this._checkCompanyPopupPosition();
    });
  }

  public onViewerShapeMouseOut(): void {
    this.currentPromoPage = null;
  }

  public onViewerLayerChange(event: CustomEvent): void { // TODO: create some type for .detail, add as T → CustomEvent<T>
    clearInterval(this.findBroadCastInterval);
    const layer = event.detail;
    const shapes = layer && layer.getShapes();
    if (!shapes || !shapes.length) {
      return;
    }
    (window as any).seatPlanLabels = (document.querySelector('.seat-plan-iframe') as HTMLIFrameElement).contentWindow.document.body.querySelectorAll('.label');
    shapes.forEach((shape: any) => {
      if (shape.TYPE !== 'booth' || !shape.label) {
        return;
      }

      const company = this.promoPageMapByExternalId.get(shape.label);

      this.findBroadCastInterval = window.setInterval(() => {
        this.activeBroadcasts = broadcastsService.getActiveBroadcasts();
        for (let i = 0; i < this.activeBroadcasts.length; i++) {
          if (this.activeBroadcasts[i].details.promopage.external_id === (company && company.external_id)) {
            if (this.broadcastItems.indexOf(company.external_id) < 0) {
              this.broadcastItems.push(company.external_id);
            }

            const frame = document.querySelector('.seat-plan-iframe') as HTMLIFrameElement;
            if (frame) {
              (window as any).companies = frame.contentWindow.document.body.querySelectorAll('.label');
              this._addActiveBroadcastIndicator();
            }
          }
        }
      }, 10000);

      if (!company) {
        return;
      }

      if (company.logo_url) {
        shape.backgroundImage = company.logo_url;
      } else {
        shape.title = company.title;
      }
    });
  }

  public onSeatPlanIframeLoad(): void {
    if (!this.$refs.setPlanIframe) {
      return;
    }
    const seatPlanContainer = (this.$refs.setPlanIframe as HTMLIFrameElement).contentWindow.document.getElementById('seat-plan-container');
    if (!seatPlanContainer) {
      return;
    }
    const seatPlanViewer = seatPlanContainer.firstChild as HTMLElement;
    if (!seatPlanViewer) {
      return;
    }

    this.seatPlanViewer = seatPlanViewer;
    (this.$refs.setPlanIframe as HTMLIFrameElement).contentWindow.document.addEventListener('click', this._onDocumentClick);
    this.seatPlanViewer.addEventListener('layerchange', this.onViewerLayerChange);
    this.seatPlanViewer.addEventListener('shapeclick', this.onViewerShapeClick);
    this.seatPlanViewer.setAttribute('seatplan', this.planJson);
  }

  public _getSeatPlanJson(): void {
    this.$store.dispatch('seatPlanStore/getSeatPlanJson', {
      eventId: this.eventId,
    });
  }

  public _onDocumentClick(event: any): void {
    const mainContainer: HTMLElement = document.querySelector('.event-map');
    const closest: HTMLElement = event.target.closest('.companies-wrapper');
    if (closest && mainContainer.contains(closest)) {
      return;
    }
    this.currentPromoPage = null;
  }

  public _checkCompanyPopupPosition(): void {
    if (!this.$refs.companySeatPlanPopup) {
      return;
    }
    const popupClientRect = (this.$refs.companySeatPlanPopup as HTMLElement).getBoundingClientRect();
    if (!popupClientRect) {
      return;
    }

    if (document.documentElement.clientWidth <= this.clientX + popupClientRect.width) {
      this.popupShowLeft = true;
    }
    if (document.documentElement.clientHeight <= this.clientY + popupClientRect.height) {
      this.popupShowTop = true;
    }
  }

  public _addActiveBroadcastIndicator(): void {
    if (!(window as any).seatPlanLabels || !(window as any).companies) {
      return;
    }
    for (let i = 0; i < (window as any).seatPlanLabels.length; i++) {

      for (let j = 0; j < this.broadcastItems.length; j++) {
        if (
          (window as any).companies[i].firstElementChild.innerHTML === this.broadcastItems[j]
          && !(window as any).companies[i].classList.contains('live-broadcast')
        ) {
          (window as any).companies[i].classList.add('live-broadcast');
          const elemNS = document.createElementNS('http://www.w3.org/2000/svg', 'image');

          elemNS.setAttributeNS(null, 'height', '1350');
          elemNS.setAttributeNS(null, 'width', '3750');
          elemNS.setAttributeNS(null, 'class', 'icon-live-indicator');
          elemNS.setAttributeNS('http://www.w3.org/1999/xlink', 'href', LIVE_INDICATOR);
          elemNS.setAttribute('x', '10');
          elemNS.setAttribute('y', '10');
          elemNS.innerHTML = ' <animate />';
          (window as any).companies[i].parentNode.insertBefore(elemNS, (window as any).companies[i].children[3]);
        }
      }
    }
  }

  public linkifyUrls(inputString: string): string {
    return ChatHelper.createLinks(inputString);
  }

}

